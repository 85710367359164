var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgBox min-w-1400"},[_c('div',{staticClass:"imgBox1 min-w-1400"},[_c('div',{staticClass:"img1Box max-w-1400"},[_vm._m(0),_c('ul',{staticClass:"msg2"},[_c('li',[_c('span',[_vm._v("Tên")]),_c('a-input',{staticClass:"inputBox",attrs:{"placeholder":"Xin vui lòng nhập"},model:{value:(_vm.formValues.email),callback:function ($$v) {_vm.$set(_vm.formValues, "email", $$v)},expression:"formValues.email"}})],1),_c('li',[_c('span',[_vm._v("E-mail")]),_c('a-input',{staticClass:"inputBox",attrs:{"placeholder":"Xin vui lòng nhập"},model:{value:(_vm.formValues.mailName),callback:function ($$v) {_vm.$set(_vm.formValues, "mailName", $$v)},expression:"formValues.mailName"}})],1),_c('li',[_c('span',[_vm._v("Thông tin")]),_c('a-textarea',{staticClass:"textareaBox",attrs:{"placeholder":"Xin vui lòng nhập","auto-size":{ minRows: 3, maxRows: 3 }},model:{value:(_vm.formValues.remark),callback:function ($$v) {_vm.$set(_vm.formValues, "remark", $$v)},expression:"formValues.remark"}})],1),_c('li',[_c('div',[_c('a-button',{class:{
                submitButton: true,
                btnBgcolor:
                  _vm.formValues.mailName !== '' && _vm.formValues.remark !== '',
              },attrs:{"type":"primary","disabled":_vm.formValues.mailName === '' || _vm.formValues.remark === '',"loading":_vm.isLoading},on:{"click":_vm.submitFn}},[_vm._v("Gửi")])],1)])])])]),_vm._m(1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"msg1"},[_c('p',[_vm._v("QUANG BINH INVESTMENT & DEVELOPMENT BANK")]),_c('p',[_c('img',{attrs:{"src":require("./components/img/local.png"),"alt":""}}),_vm._v("3 Nguyen Trai St., Hai Dinh Ward, Dong Hoi Township, Dong Hoi ")]),_c('p',[_c('img',{attrs:{"src":require("./components/img/email.png"),"alt":""}}),_vm._v("office@quanbinhidbank.com ")]),_c('p',[_c('img',{attrs:{"src":require("./components/img/phone.png"),"alt":""}}),_vm._v("+84 581212941")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgBox2 min-w-1400"},[_c('div',{staticClass:"img2Box max-w-1400"},[_c('p',[_vm._v("Copyright ©QUANG BINH INVESTMENT & DEVELOPMENT BANK")])])])
}]

export { render, staticRenderFns }