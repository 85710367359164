<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1Box max-w-1400">
        <!-- <img :src="importedImg1" alt="" class="imgItem1" /> -->
        <div class="msg1">
          <p>QUANG BINH INVESTMENT & DEVELOPMENT BANK</p>
          <p>
            <img src="./components/img/local.png" alt="" />3 Nguyen Trai St.,
            Hai Dinh Ward, Dong Hoi Township, Dong Hoi
          </p>
          <p>
            <img
              src="./components/img/email.png"
              alt=""
            />office@quanbinhidbank.com
          </p>
          <p><img src="./components/img/phone.png" alt="" />+84 581212941</p>
        </div>
        <ul class="msg2">
          <li>
            <span>Tên</span>
            <a-input
              class="inputBox"
              v-model="formValues.email"
              placeholder="Xin vui lòng nhập"
            >
              <!-- <template #suffix>
                <img :src="mailIcon" />
              </template> -->
            </a-input>
          </li>
          <li>
            <span>E-mail</span>
            <a-input
              class="inputBox"
              v-model="formValues.mailName"
              placeholder="Xin vui lòng nhập"
            >
              <!-- <template #suffix>
                <img :src="mailIcon" />
              </template> -->
            </a-input>
          </li>
          <li>
            <span>Thông tin</span>
            <a-textarea
              class="textareaBox"
              v-model="formValues.remark"
              placeholder="Xin vui lòng nhập"
              :auto-size="{ minRows: 3, maxRows: 3 }"
            />
          </li>
          <li>
            <div>
              <a-button
                type="primary"
                :disabled="
                  formValues.mailName === '' || formValues.remark === ''
                "
                :class="{
                  submitButton: true,
                  btnBgcolor:
                    formValues.mailName !== '' && formValues.remark !== '',
                }"
                @click="submitFn"
                :loading="isLoading"
                >Gửi</a-button
              >
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2Box max-w-1400">
        <!-- <img :src="importedImg2" alt="" class="imgItem2" /> -->
        <p>Copyright ©QUANG BINH INVESTMENT & DEVELOPMENT BANK</p>
      </div>
    </div>
  </div>
</template>

<script>
import "./index.css";
import { message } from "ant-design-vue";
// import importedImg1 from "@/page/quanbinhidbank/components/img/footer.png";

export default {
  data() {
    return {
      // importedImg1: importedImg1,

      altText: "图片",

      formValues: {
        mailName: "",
        email: "",
        remark: "",
      },
      isLoading: false,
    };
  },
  methods: {
    gotoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    submitFn() {
      if (this.formValues.mailName === "" || this.formValues.remark === "") {
        message.info("必填项不能为空");
        return;
      }
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        message.success("邮件已发送成功，我们将及时回复");
        this.formValues = {
          mailName: "",
          email: "",
          remark: "",
        };
      }, 2000);
    },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}

img {
  display: block;
}

.imgBox1,
.imgBox2,
.imgBox3,
.imgBox4,
.imgBox5,
.imgBox6 {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
}

.img1Box,
.img2Box,
.img3Box,
.img4Box,
.img5Box,
.img6Box {
  width: 100%;
  overflow: hidden;
  background-color: "";
}

.imgItem1,
.imgItem2,
.imgItem3,
.imgItem4,
.imgItem5,
.imgItem6 {
  width: 100%;
  margin: auto;
}

.img1Box {
  background-color: #0069f9;
  display: flex;
  justify-content: space-between;
  padding: 40px 200px;
  gap: 20px;
}

/* .msg1 {
  border: 1px solid red;
} */
.msg1 p {
  display: flex;
  color: #ffffff;
  align-items: center;
  /* text-align: center; */
  font-family: "Thonburi";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
}
.msg1 p img {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
.msg1 p:first-child {
  color: #ffffff;
  font-size: 22px;
  font-weight: 700;
}
.msg1 p span {
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
}

.msg2 {
  /* border: 1px solid red; */
  display: flex;
  flex-wrap: wrap;
  width: 502px;
  gap: 20px;
}
.msg2 li {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  width: 240px;
}

.msg2 li:nth-child(3) {
  width: 500px;
}

.msg2 li span {
  color: #ffffff;
  font-family: "Thonburi";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  opacity: 0.6;
  padding-bottom: 10px;
}
.msg2 li input,
textarea {
  all: unset; /* 移除所有默认样式 */
  border: none; /* 去除边框 */
  outline: none; /* 去除聚焦时的轮廓 */
  background: none; /* 去除背景 */
  color: inherit; /* 继承父元素的文本颜色 */
  font: inherit; /* 继承父元素的字体 */
  /* border: 1px solid red; */
  height: 30px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #f8f6f4;
  padding: 10px;
}

.msg2 li a {
  width: 200px;
  height: 50px;
  line-height: 50px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #8a5d3c;
  color: #ffffff;
  text-align: center;
  font-family: "Thonburi";
  font-size: 20px;
  font-weight: 700;
}

.form {
  display: flex;
  flex-direction: column;
}

.inputBox {
  /* font-size: 18px;
  border: 2px solid #cdcdcd;
  border-radius: 10px;
  height: 50px; */
}

.inputBox >>> .ant-input {
  font-size: 18px;
  border: 2px solid #ffffff;
  border-radius: 10px;
  height: 56px;
  background-color: #ffffff;
}

/* .inputBox>>>.ant-input:focus {
  border: none;
  border-bottom: 1px solid white !important;
  box-shadow: 0 0 1px 2px #4263FF;
}

.inputBox>>>.ant-input:hover  {
  border: none;
  border-bottom: 1px solid white !important;
  box-shadow: 0 0 1px 2px #4263FF;
} */

.textareaBox {
  margin-top: 20px;
  border: 2px solid #ffffff;
  border-radius: 10px;
  font-size: 18px;
}
.textareaBox .ant-input {
  height: 200px;
}

.submitButton {
  background-color: #ffffff !important;
  color: #0069f9;
  border: none;
  height: 40px;
  border-radius: 5px;
  margin-top: 20px;
  width: 120px;
  font-size: 18px;
}

.btnBgcolor {
  /* background-color: #ffffff !important; */
  color: #0069f9;
}

.submitButton:hover {
  background-color: #ffffff;
  color: #0069f9;
}

.imgItem2 {
  width: 80%;
  margin-top: 20px;
}

.inp {
  display: flex;
}

.img2Box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  color: #ffffff;
  text-align: center;
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  background-color: #0069f9;
  border-top: 1px solid #ffffff;
}
</style>
